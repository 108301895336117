<template>
  <!--添加预约商品-->
  <div class="page1">
    <!-- 内容 -->
    <div class="content">
      <h2 class="title">
        <span class="title_icon"></span>
        编辑优惠规则
      </h2>
      <div class="main">
        <span>优惠规则</span>
        <i class="el-icon-circle-plus-outline addTicket add" @click="add"></i>
        <el-form
          :model="formInline"
          style="margin-top: 40px"
          ref="form"
          :rules="rules"
        >
          <el-form-item
            v-for="(item, index) in formInline.detailVOS"
            class="dynamic"
            :style="{
              marginBottom:
                index !== formInline.detailVOS.length - 1 ? '30px' : '60px',
            }"
            :key="index"
          >
            <span style="margin-right: 10px">实际消费</span>
            <el-form-item
              label=""
              :prop="'detailVOS.' + index + '.money'"
              :rules="[
                {
                  required: true,
                  message: '请输入实际消费金额',
                  trigger: 'blur',
                },
                {
                  pattern: /^(0|[1-9]\d{0,7})$/g,
                  message: '请输入整数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="item.money"
                style="width: 110px"
                maxlength="8"
              ></el-input>
            </el-form-item>
            <span style="margin: 0 10px">元后 代付车费折扣比例</span>
            <el-form-item
              label=""
              :prop="'detailVOS.' + index + '.discount'"
              :rules="[
                { required: true, message: '请输入折扣比例', trigger: 'blur' },
                {
                  pattern: /^0.([1-9][0-9]?|0[1-9])|1$/g,
                  message: '请输入0-1之间的数，最多两位小数',
                  trigger: 'blur',
                },
              ]"
            >
              <el-input
                v-model="item.discount"
                style="width: 100px"
                maxlength="4"
              ></el-input>
            </el-form-item>
            <i
              class="el-icon-remove-outline addTicket remove"
              @click="remove(index)"
            ></i>
          </el-form-item>
          <el-form-item label="充值优惠说明" prop="ruleContent">
            <el-input
              v-model="formInline.ruleContent"
              style="width: 600px; display: block"
              type="textarea"
              maxlength="9999"
              minlength="10"
              :autosize="{ minRows: 6, maxRows: 8 }"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <el-button type="primary" style="margin-top: 20px" @click="save"
          >{{ $t('button.preservation') }}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      storeRechargeDiscountRuleId: "",
      formInline: {
        detailVOS: [],
        ruleContent: "",
      },
      rules: {
        ruleContent: [
          { required: true, message: "请输入充值优惠说明", trigger: "blur" },
          { min: 10, max: 9999, message: "最少输入10个字", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    add() {
      // 添加的时候验证之前填写的
      let moneyList = [];
      let discountList = [];
      this.formInline.detailVOS.forEach((item) => {
        moneyList.push(item.money);
        discountList.push(item.discount);
      });
      console.log(moneyList, discountList);
      if (
        this.checkoutPositiveList(moneyList) &&
        this.checkoutReverseList(discountList)
      ) {
        this.formInline.detailVOS.push({
          money: "",
          discount: "",
        });
      } else {
        this.$message.error("请输入正确的优惠规则");
      }
    },
    // 添加的时候验证之前填写的(判断数组是否正序)
    checkoutPositiveList(list) {
      if (list.length == 1) {
        return true;
      }
      for (let i = 0; i < list.length - 1; i++) {
        if (list[i] >= list[i + 1]) {
          return false;
        }
      }
      return true;
    },
    // 添加的时候验证之前填写的(判断数组是否正序)
    checkoutReverseList(list) {
      if (list.length == 1) {
        return true;
      }
      for (let i = 0; i < list.length - 1; i++) {
        if (list[i] < list[i + 1]) {
          return false;
        }
      }
      return true;
    },

    remove(index) {
      let detailVOS = this.formInline.detailVOS;
      detailVOS.splice(index, 1);
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let moneyList = [];
          let discountList = [];
          this.formInline.detailVOS.forEach((item) => {
            moneyList.push(item.money);
            discountList.push(item.discount);
          });
          if (
            !this.checkoutPositiveList(moneyList) ||
            !this.checkoutReverseList(discountList)
          ) {
            this.$message.error("请输入正确的优惠规则");
            return 0;
          }
          const opt = {
            url: "/acb/2.0/storeRechargeDiscountRule/addRule",
            method: "post",
            data: {
              storeRechargeDiscountRuleId:
                this.storeRechargeDiscountRuleId || "",
              ruleContent: this.formInline.ruleContent,
              detailVOS: this.formInline.detailVOS,
            },
            config: {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            },
            success: (res) => {
              if (res.state == 0) {
                this.$message({
                  message: res.desc,
                  type: "success",
                });
                this.getRuleDetail();
              }
            },
          };
          this.$request(opt);
        } else {
          return false;
        }
      });
    },
    getRuleDetail() {
      const opt = {
        url: "/acb/2.0/storeRechargeDiscountRule/getRule",
        method: "get",
        success: (res) => {
          if (res.state == 0) {
            const { storeRechargeDiscountRuleId, ruleContent, detailVOS } =
              res.value;
            this.storeRechargeDiscountRuleId =
              storeRechargeDiscountRuleId || "";
            this.formInline.ruleContent = ruleContent || "";
            this.formInline.detailVOS = detailVOS || [];
          }
        },
      };
      this.$request(opt);
    },
  },
  created() {
    this.$nextTick(() => {
      this.getRuleDetail();
    });
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.content {
  background: #FFFFFF;
  overflow: hidden;
  border: 1px solid #C0CCDA;
  border-radius: 4px;
  padding: 20px;

  .title {
    font-size: 14px;
  }

  .main {
    margin: 30px 10px;

    .addTicket {
      cursor: pointer;
      font-size: 30px;
      margin-left: 20px;
      width: 30px;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
    }

    .add {
      color: #409EFF;
    }

    .remove {
      color: #F56C6C;
      margin-top: 5px;
    }

    /deep/ .dynamic .el-form-item__content {
      display: flex;
    }

    /deep/ .dynamic .el-form-item__error {
      width: 100%;
      text-align: center;
    }
  }
}
</style>
