var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("span", [_vm._v("优惠规则")]),
          _c("i", {
            staticClass: "el-icon-circle-plus-outline addTicket add",
            on: { click: _vm.add },
          }),
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { "margin-top": "40px" },
              attrs: { model: _vm.formInline, rules: _vm.rules },
            },
            [
              _vm._l(_vm.formInline.detailVOS, function (item, index) {
                return _c(
                  "el-form-item",
                  {
                    key: index,
                    staticClass: "dynamic",
                    style: {
                      marginBottom:
                        index !== _vm.formInline.detailVOS.length - 1
                          ? "30px"
                          : "60px",
                    },
                  },
                  [
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v("实际消费"),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "",
                          prop: "detailVOS." + index + ".money",
                          rules: [
                            {
                              required: true,
                              message: "请输入实际消费金额",
                              trigger: "blur",
                            },
                            {
                              pattern: /^(0|[1-9]\d{0,7})$/g,
                              message: "请输入整数",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "110px" },
                          attrs: { maxlength: "8" },
                          model: {
                            value: item.money,
                            callback: function ($$v) {
                              _vm.$set(item, "money", $$v)
                            },
                            expression: "item.money",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("span", { staticStyle: { margin: "0 10px" } }, [
                      _vm._v("元后 代付车费折扣比例"),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "",
                          prop: "detailVOS." + index + ".discount",
                          rules: [
                            {
                              required: true,
                              message: "请输入折扣比例",
                              trigger: "blur",
                            },
                            {
                              pattern: /^0.([1-9][0-9]?|0[1-9])|1$/g,
                              message: "请输入0-1之间的数，最多两位小数",
                              trigger: "blur",
                            },
                          ],
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: { width: "100px" },
                          attrs: { maxlength: "4" },
                          model: {
                            value: item.discount,
                            callback: function ($$v) {
                              _vm.$set(item, "discount", $$v)
                            },
                            expression: "item.discount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("i", {
                      staticClass: "el-icon-remove-outline addTicket remove",
                      on: {
                        click: function ($event) {
                          return _vm.remove(index)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "el-form-item",
                { attrs: { label: "充值优惠说明", prop: "ruleContent" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "600px", display: "block" },
                    attrs: {
                      type: "textarea",
                      maxlength: "9999",
                      minlength: "10",
                      autosize: { minRows: 6, maxRows: 8 },
                    },
                    model: {
                      value: _vm.formInline.ruleContent,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "ruleContent", $$v)
                      },
                      expression: "formInline.ruleContent",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "el-button",
            {
              staticStyle: { "margin-top": "20px" },
              attrs: { type: "primary" },
              on: { click: _vm.save },
            },
            [_vm._v(_vm._s(_vm.$t("button.preservation")))]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "title" }, [
      _c("span", { staticClass: "title_icon" }),
      _vm._v(" 编辑优惠规则 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }